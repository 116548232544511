/*
 * @Date: 2022-10-24 09:17:00
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-27 16:17:47
 * @FilePath: \ulinked\store\modules\tabar.js
 * @Description:底部导航栏全局缓存
 */
import { defineStore } from 'pinia';
export const useTabbarStore = defineStore('tabbarStore', {
  state() {
    return {
      curPage: {
        name: 'home',
        index: 0,
      },
      list: [
        {
          name: 'home',
          text: '首页',
          icon: 'home',
          defaultImg: '',
          activeImg: '',
          badge: 0,
          dot: false,
          badgeStyle: {
            top: '12rpx',
            right: '4rpx',
          },
        },
        //#ifdef APP
        {
          name: 'message',
          text: '消息',
          icon: 'chat',
          defaultImg: '',
          activeImg: '',
          badge: 0,
          dot: false,
          badgeStyle: {
            top: '12rpx',
            right: '4rpx',
          },
        },
        //#endif
        {
          name: 'appointment',
          text: '约会',
          icon: 'man-add',
          defaultImg: '',
          activeImg: '',
          badge: 0,
          dot: false,
          badgeStyle: {
            top: '12rpx',
            right: '4rpx',
          },
        },
        {
          name: 'my',
          text: '我的',
          icon: 'star',
          defaultImg: '',
          activeImg: '',
          badge: 0,
          dot: false,
          badgeStyle: {
            top: '12rpx',
            right: '4rpx',
          },
        },
      ],
    }; //角标数量 //是否下显示红点 //距离顶部距离 //距离右边的距离 //角标数量 //是否下显示红点 //距离顶部距离 //距离右边的距离 //角标数量 //是否下显示红点 //距离顶部距离 //距离右边的距离 //角标数量 //是否下显示红点 //距离顶部距离 //距离右边的距离
  }, // actions 用来修改 state
  actions: {},
  unistorage: {
    // 开启后对 state 的数据读写都将持久化
    paths: ['list'],
  },
}); // 缓存的键 // 需要缓存的路径，
export default useTabbarStore;
