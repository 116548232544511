/*
 * @Date: 2022-10-27 16:28:23
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-28 13:54:21
 * @FilePath: \ulinked\common\route.js
 * @Description:
 */
const { http } = uni.$u;
//公共
export const common = {
  //获取启动页
  getSplah: (params, config = {}) => http.post('/app/queryStartupList', params, config),
  //获取升级
  getUpgrade: (params, config = {}) => http.post('/app/upgrade', params, config),
  //获取全局配置
  getConfig: (params, config = {}) => http.post('/app/config', params, config),
  //获取微信h5签名
  getJsapiInfo: (params, config = {}) => http.post('/app/getJsapiInfo', params, config),
  //举报接口
  report: (params, config = {}) => http.post('/report/submit', params, config),
  //意见反馈接口
  feedback: (params, config = {}) => http.post('/feedback/submit', params, config),
  //获取u币选项列表
  getUBItemList: (params, config = {}) => http.post('/ucoin/queryItemList', params, config),
  //获取会员支付列表
  getVipPayItemList: (params, config = {}) => http.post('/member/queryItemList', params, config),
  //更新地理位置
  updateLocaiton: (params, config = {}) => http.post('/user/updateLocation', params, config),
  //记录用户登录信息
  recordUserInfo: (params, config = {}) => http.post('/user/record', params, config),
  //微信小程序获取手机号码
  getMiniAppPhone: (params, config = {}) => http.post('/user/getMiniAppPhone', params, config),
};
//用户
export const user = {
  //注册账号
  register: (params, config = {}) => http.post('/user/register', params, config),
  //微信注册账号
  registerWxMini: (params, config = {}) => http.post('/user/miniAppRegister', params, config),
  registerWxApp: (params, config = {}) => http.post('/user/appRegister', params, config),
  registerWxH5: (params, config = {}) => http.post('/user/h5Register', params, config),
  //登录接口
  login: (params, config = {}) => http.post('/user/login', params, config),
  //微信登录
  loginWxMini: (params, config = {}) => http.post('/user/miniAppLogin', params, config),
  loginWxApp: (params, config = {}) => http.post('/user/appLogin', params, config),
  loginWxH5: (params, config = {}) => http.post('/user/h5Login', params, config),
  //注销账户
  logout: (params, config = {}) => http.post('/user/delete', params, config),
  //获取微信小程序openid
  getWxMiniOpenId: (params, config = {}) => http.post('/order/getOpenid', params, config),

  //发送手机号验证码
  getMobileCode: (params, config = {}) => http.post('/code/send', params, config),
  //校验手机号验证码
  validateMobileCode: (params, config = {}) => http.post('/code/verify', params, config),
  //绑定手机号
  bindMobile: (params, config = {}) => http.post('/user/bindPhone', params, config),
  //绑定微信号
  bindWx: (params, config = {}) => http.post('/user/bindWx', params, config),
  //查询微信绑定
  findWx: (params, config = {}) => http.post('/user/queryWx', params, config),
  //修改绑定微信的用户信息
  bindMiniApp: (params, config = {}) => http.post('/user/bindMiniApp', params, config),
  //找回用户密码
  updatePassword: (params, config = {}) => http.post('/user/updatePassword', params, config),
  //根据旧密码修改密码
  updatePassword2: (params, config = {}) => http.post('/user/updatePassword2', params, config),
  //获取我的页面信息
  getMyPageInfo: (params, config = {}) => http.post('/user/query', params, config),
  //获取会员详情
  getMemberInfo: (params, config = {}) => http.post('/member/query', params, config),
  //获取u币使用记录
  getUseUcoinLog: (params, config = {}) => http.post('/ucoin/queryRecordList', params, config),
  //解锁相册
  unlockAlbum: (params, config = {}) => http.post('/homePage/unlockAlbum', params, config),
  //设置URL图片为头像
  updateHeadUrl: (params, config = {}) => http.post('/userData/updateHeadUrl', params, config),

  //我的资料
  myInformation: {
    uploadAvatar: (params) => http.upload('/userData/uploadHeadUrl', params),
    getUserInfo: (params, config = {}) => http.post('/userData/queryUserInfo', params, config),
    updateUserInfo: (params, config = {}) => http.post('/userData/updateUserInfo', params, config),
    getHobbyInfo: (params, config = {}) => http.post('/userData/queryHobbyInfo', params, config),
    updateHobbyInfo: (params, config = {}) => http.post('/userData/updateHobbyInfo', params, config),
    getHeartVoice: (params, config = {}) => http.post('/userData/queryHeartVoice', params, config),
    updateHeartVoice: (params, config = {}) => http.post('/userData/updateHeartVoice', params, config),
  },
  //我的相册
  myAlbum: {
    //上传相册
    upload: (params) => http.upload('/userAlbum/upload', params),
    //获取我的相册列表
    getList: (params, config = {}) => http.post('/userAlbum/query', params, config),
    //更新我的相册列表
    updateList: (params, config = {}) => http.post('/userAlbum/update', params, config),
    delete: (params, config = {}) => http.post('/userAlbum/delete', params, config),
  },
  //择偶标准
  mateSelection: {
    //查询用户择偶条件
    get: (params, config = {}) => http.post('/userSelect/query', params, config),
    //更新用户择偶条件
    update: (params, config = {}) => http.post('/userSelect/update', params, config),
  },
  //我的收藏
  myCollect: {
    //添加收藏
    confirm: (params, config = {}) => http.post('/collect/confirm', params, config),
    //取消收藏
    cancel: (params, config = {}) => http.post('/collect/cancel', params, config),
    //获取我的收藏列表
    getMyList: (params, config = {}) => http.post('/collect/queryList', params, config),
    //查询收藏我的列表
    findCollectMeList: (params, config = {}) => http.post('/collect/queryList4Me', params, config),
  },
  //查询最新访客列表
  getAccessList: (params, config = {}) => http.post('/access/queryList', params, config),
  //用户征婚接口
  ad: {
    upload: (params, config = {}) => http.upload('/ad/upload', params, config),
    create: (params, config = {}) => http.post('/ad/create', params, config),
    update: (params, config = {}) => http.post('/ad/update', params, config),
    delete: (params, config = {}) => http.post('/ad/delete', params, config),
    publish: (params, config = {}) => http.post('/ad/publish', params, config),
    getList: (params, config = {}) => http.post('/ad/queryList', params, config),
    getInfo: (params, config = {}) => http.post('/ad/queryById', params, config),
  },
};
//首页
export const home = {
  //获取banner列表
  getBannerList: (params, config = {}) => http.post('/homePage/queryBannerList', params, config),
  //获取征婚列表
  getAdList: (params, config = {}) => http.post('/homePage/queryAdList', params, config),
  //获取用户详情列表接口
  getUserInfoList: (params, config = {}) => http.post('/homePage/queryUserList', params, config),
  //获取首页最新列表
  getNewUserList: (params, config = {}) => http.post('/homePage/queryNewUserList', params, config),
  //获取首页推荐列表哦
  getRemdUserList: (params, config = {}) => http.post('/homePage/queryRemdUserList', params, config),
  //搜索用户列表
  findUserList: (params, config = {}) => http.post('/homePage/searchUserList', params, config),
  //查询用户详情
  findUserInfo: (params, config = {}) => http.post('/homePage/queryUserDetail', params, config),
  //交换微信
  transformWx: (params, config = {}) => http.post('/homePage/wxExchange', params, config),
};
//消息
export const msg = {
  //发送极光消息
  send: (params, config = {}) => http.post('/message/send', params, config),
};
//约会
export const appointment = {
  //获取约会人员列表
  getUserList: (params, config = {}) => http.post('/appointment/queryUserList', params, config),
  //约会列表
  inviteMeList: (params, config = {}) => http.post('/appointment/queryInviteList', params, config),
  //获取邀请我的列表
  // inviteMeList: (params, config = {}) => http.post('/appointment/queryInviteMeList', params, config),
  //获取我发起邀请的列表
  // inviteHeList: (params, config = {}) => http.post('/appointment/queryIInviteList', params, config),
  //获取约会详情
  getInfo: (params, config = {}) => http.post('/appointment/queryById', params, config),
  //发起约会
  create: (params, config = {}) => http.post('/appointment/create', params, config),
  //接受约会邀请
  agree: (params, config = {}) => http.post('/appointment/agree', params, config),
  //拒绝约会邀请
  refuse: (params, config = {}) => http.post('/appointment/refuse', params, config),
  //更新了约会
  update: (params, config = {}) => http.post('/appointment/update', params, config),
  //删除约会
  delete: (params, config = {}) => http.post('/appointment/delete', params, config),
};
//订单
export const order = {
  //创建app支付订单
  createApp: (params, config = {}) => http.post('/order/create', params, config),
  //创建微信小程序支付订单
  createWXMINI: (params, config = {}) => http.post('/order/createWX', params, config),
  //创建公众号支付订单
  createWXH5: (params, config = {}) => http.post('/order/createWXH5', params, config),
  //创建h5支付订单
  createH5: (params, config = {}) => http.post('/order/createH5', params, config),
};
//活动
export const activity = {
  getList: (params, config = {}) => http.post('/activity/searchList', params, config),
  getInfo: (params, config = {}) => http.post('/activity/queryDetail', params, config),
  //报名
  apply: (params, config = {}) => http.post('/activity/signUp', params, config),
  //更新用户信息
  updateUserInfo: (params, config = {}) => http.post('/activity/updateUserInfo', params, config),
};
//红娘
export const matchmaker = {
  //活动报名列表
  activityApplyList: (params, config = {}) => http.post('/activity/searchSignUpList', params, config),
  //活动报名用户详情接口
  activityApplyUserInfo: (params, config = {}) => http.post('/activity/querySignUpInfo', params, config),
  //活动签到
  activityApplyUserSign: (params, config = {}) => http.post('/activity/sign', params, config),
  //活动报名用户备注
  activityApplyUserRemark: (params, config = {}) => http.post('/activity/updateMemo', params, config),
  //活动报名用户删除
  activityApplyUserRemove: (params, config = {}) => http.post('/activity/deleteSignUp', params, config),
  //管理的用户列表
  managerUserList: (params, config = {}) => http.post('/user/queryHongniangUserList', params, config),
};
//两性话题
export const sexSubject = {
  getList: (params, config = {}) => http.post('/sexSubject/queryList', params, config),
  getInfo: (params, config = {}) => http.post('/sexSubject/queryDetail', params, config),
};
//爱情故事
export const loveStory = {
  getList: (params, config = {}) => http.post('/loveStory/queryList', params, config),
  getInfo: (params, config = {}) => http.post('/sexSubject/queryDetail', params, config),
};
