/*
 * @Date: 2022-10-26 15:13:48
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-27 16:58:51
 * @FilePath: \ulinked\main.js
 * @Description:
 */
// #ifdef H5
import 'default-passive-events';
// #endif
import '@/lib/date/index.js';
import App from './App';
import uviewPlus from 'uview-plus';
import 'uno.css';

import * as Pinia from 'pinia';

import { createUnistorage } from '@/lib/piniaStorage';
import { initRequest } from '@/lib/request/index.js';
import * as api from '@/common/route.js';
import platform from '@/lib/Platform/index.js';
// #ifndef VUE3
import Vue from 'vue';
Vue.config.productionTip = false;
App.mpType = 'app';
const app = new Vue({
  ...App,
});
app.$mount();
// #endif

// #ifdef VUE3
import { createSSRApp } from 'vue';
const store = Pinia.createPinia();
store.use(createUnistorage());
export function createApp() {
  const app = createSSRApp(App);
  initRequest(app);
  uni.$api = api;
  uni.$platform = platform;

  app.use(uviewPlus);
  app.use(store);
  return {
    app,
    Pinia,
  };
}
// #endif
