/*
 * @Date: 2022-10-24 09:17:00
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-24 11:56:33
 * @FilePath: \ulink\src\store\modules\globalConfig.ts
 * @Description:用户详情
 */
import {
	defineStore
} from 'pinia';
export const useAddressStore = defineStore(
	'addressStore', {
		state: () => {
			return {
				areaNo: '110000', //城市编码 默认北京
				areaName: '北京市', //城市名称默认北京市
				longitude: '116.2', //经度
				latitude: '39.56', //纬度
			};
		},
		// actions 用来修改 state
		actions: {},
		unistorage: {
			// 开启后对 state 的数据读写都将持久化
			paths: ['areaNo', 'areaName', 'longitude', 'latitude'], // 需要缓存的路径，
		},
	},
);
export default useAddressStore;
