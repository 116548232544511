/*
 * @Date: 2022-10-27 16:25:41
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-27 17:11:29
 * @FilePath: \ulinked\common\config.js
 * @Description:
 */
export const config = {
	//请求地址
	//#ifdef MP-WEIXIN
	// baseUrl:'https://testapp.ulinked.cn/service/portal/',
	baseUrl: __wxConfig.envVersion == 'release' ?
		'https://app.ulinked.cn/service/portal/' : 'https://testapp.ulinked.cn/service/portal/',
	//#endif
	//#ifndef MP-WEIXIN
	// baseUrl: 'https://testapp.ulinked.cn/service/portal/',
	baseUrl: process.env.NODE_ENV === 'development' ?
		'https://testapp.ulinked.cn/service/portal/' :
		'https://app.ulinked.cn/service/portal/',
	//#endif
	//远程图片地址
	imgUrl: 'https://ulinked.oss-cn-shanghai.aliyuncs.com',
	//系统级本地图片地址
	localImgUrl: '',
	// baseUrl: 'http://app.ulinked.cn/service/portal',
};