// #ifdef H5

import * as wxjssdk from 'weixin-js-sdk';

let jsApiList = [
	'checkJsApi',
	'chooseWXPay',
	'closeWindow',
	'getLocation',
	'openLocation',
	'getNetworkType',
	'getLocalImgData',
	'downloadImage',
	'uploadImage',
	'previewImage',
	'chooseImage',
	'onMenuShareAppMessage',
	'updateAppMessageShareData',
	'onMenuShareTimeline',
	'updateTimelineShareData',
];
// const appSecret = '50225506601225f449552307e7e92b3a';
// const appId = 'wx9615f9f360d7bc59';
export const appId = process.env.NODE_ENV === 'development' ? 'wx9615f9f360d7bc59' : 'wx37a19539f1b2502d';
let wxconfig = {
	debug: false,
	appId,
	timestamp: '',
	nonceStr: '',
	signature: '',
	jsApiList,
};

// export function getSignature() {
// 	let _config = [wxconfig.nonceStr, wxconfig.timestamp, wxconfig.url].sort().join('');

// }
export function initConfig(options) {
	return new Promise(async (resolve, reject) => {
		let url = uni.getStorageSync('webUrl');
		let query = await uni.$api.common.getJsapiInfo({
			url,
		});
		if (query.code != '0') {
			Promise.reject(query);
			return;
		}
		wxconfig.timestamp = query.timestamp;
		wxconfig.nonceStr = query.nonceStr;
		wxconfig.signature = query.signature;
		console.log('query', query);
		wxjssdk.ready(() => {
			resolve();
		});
		wxjssdk.error((err) => {
			reject(err);
		});
		wxjssdk.config(wxconfig);
	});
}
// H5获取code
export async function loginWxh5() {
	let local = window.location.href;
	//不存在就打开上面的地址进行授权
	let hrefUrl =
		'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
		wxconfig.appId +
		'&redirect_uri=' +
		encodeURIComponent(local) +
		'&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
	console.log('hrefUrl', hrefUrl);
	window.location.href = hrefUrl;
	return;
}

/**
 * 更新分享消息的信息
 * @param title 标题
 * @param desc 描述
 * @param link 地址
 * @param imgUrl 图片
 */
export function updateAppMessageShareDate(title = '', desc = '', link = '', imgUrl = '') {
	console.log(title, desc, link, imgUrl);
	return new Promise((resolve, reject) => {
		wxjssdk.updateAppMessageShareData({
			title: title, // 分享标题
			link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			desc: desc, // 分享描述
			imgUrl: imgUrl, // 分享图标
			success: function(res) {
				console.log(res);
				// 设置成功
				resolve();
			},
			fail: function(err) {
				reject(err);
			},
		});
		wxjssdk.onMenuShareAppMessage({
			title: title, // 分享标题
			link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			desc: desc, // 分享描述
			imgUrl: imgUrl, // 分享图标
			success: function(res) {
				// 设置成功
				resolve();
			},
			fail: function(err) {
				reject(err);
			},
		});
	});
}

/**
 * 更新分享到朋友圈消息
 * @param title 标题
 * @param link 地址
 * @param imgUrl 图片
 */
export function updateTimelineShareData(title = '', link = '', imgUrl = '') {
	return new Promise((resolve, reject) => {
		wxjssdk.updateTimelineShareData({
			title: title, // 分享标题
			link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			imgUrl: imgUrl, // 分享图标
			success: function(res) {
				console.log(res);
				// 设置成功
				resolve();
			},
			fail: function(err) {
				reject(err);
			},
		});
		wxjssdk.onMenuShareTimeline({
			title: title, // 分享标题
			link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			imgUrl: imgUrl, // 分享图标
			success: function(res) {
				// 设置成功
				resolve();
			},
			fail: function(err) {
				reject(err);
			},
		});
	});
}

/**
 * 获取本地图片
 * @param {Object} count
 */
export function chooseImage(count) {
	return new Promise((resolve, reject) => {
		wxjssdk.chooseImage({
			count: 1, // 默认9
			sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
			sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
			success: function(res) {
				var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
			},
		});
	});
}

/**
 * 预览图片
 * @param {Object} current 当前下标
 * @param {Object} urls 图片集合
 */
export function previewImage(current = 0, urls = []) {
	return new Promise((resolve, reject) => {
		wxjssdk.previewImage({
			current, // 当前显示图片的http链接
			urls, // 需要预览的图片http链接列表
		});
	});
}

/**
 * 上传那图片到微信服务器
 */
export function uploadImage(localId = '') {
	return new Promise((resolve, reject) => {
		wxjssdk.uploadImage({
			localId, // 需要上传的图片的本地ID，由chooseImage接口获得
			isShowProgressTips: 1, // 默认为1，显示进度提示
			success: function(res) {
				var serverId = res.serverId; // 返回图片的服务器端ID
			},
		});
	});
}

/**
 * 下载图片到本地
 */
export function downloadImage(serverId = '') {
	return new Promise((resolve, reject) => {
		wxjssdk.downloadImage({
			serverId, // 需要下载的图片的服务器端ID，由uploadImage接口获得
			isShowProgressTips: 1, // 默认为1，显示进度提示
			success: function(res) {
				// var localId = res.localId; // 返回图片下载后的本地ID
				resolve(res);
			},
		});
	});
}

/**
 * 获取图片详细信息
 * @param localId 图片的localID
 */
export function getLocalImgData(localId = '') {
	return new Promise((resolve, reject) => {
		wxjssdk.getLocalImgData({
			localId, // 图片的localID
			success: function(res) {
				// var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
				resolve(res);
			},
			fail: function(err) {
				reject(err);
			},
		});
	});
}

/**
 * 获取地理位置
 */
export function getLocation() {
	return new Promise((resolve, reject) => {
		wxjssdk.getLocation({
			type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
			success: function(res) {
				resolve(res);
				// var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
				// var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
				// var speed = res.speed; // 速度，以米/每秒计
				// var accuracy = res.accuracy; // 位置精度
			},
			fail: function(err) {
				reject(err);
			},
		});
	});
}

/**
 * 打开地图
 */
export function openLocation(latitude = 0, longitude = 0, name = '', address = '', scale = 1, infoUrl = '') {
	return new Promise((resolve, reject) => {
		wxjssdk.openLocation({
			latitude, // 纬度，浮点数，范围为90 ~ -90
			longitude, // 经度，浮点数，范围为180 ~ -180。
			name, // 位置名
			address, // 地址详情说明
			scale, // 地图缩放级别,整型值,范围从1~28。默认为最大
			infoUrl, //在查看位置界面底部显示的超链接,可点击跳转
			success: function(res) {
				// var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
				resolve(res);
			},
			fail: function(err) {
				reject(err);
			},
		});
	});
}

/**
 * 关闭当前网页
 */
export function closeWindow() {
	wxjssdk.closeWindow();
}
/**
 * @name hideMenuItems
 * @description 隐藏一些按钮选项
 * @param menuList 按钮数组
 */
export function hideMenuItems(
	menuList = [
		// 'menuItem:share:appMessage', // 分享给朋友
		// 'menuItem:share:timeline', // 分享到朋友圈
		'menuItem:share:qq', // 分享到 QQ
		'menuItem:share:weiboApp', // 分享到Weibo
		// 'menuItem:favorite', // 收藏
		'menuItem:share:facebook', // 分享到 Facebook
		'menuItem:share:QZone', // 分享到 QQ 空间
		'menuItem:editTag', // 编辑标签
		'menuItem:delete', // 删除
		// 'menuItem:copyUrl', // 复制链接
		'menuItem:originPage', // 原网页
		'menuItem:readMode', // 阅读模式
		'menuItem:openWithQQBrowser', // 在QQ浏览器中打开
		'menuItem:openWithSafari', // 在Safari中打开
		'menuItem:share:email', // 邮件
		'menuItem:share:brand', // 一些特殊公众号
	],
) {
	wxjssdk.hideMenuItems({
		menuList,
		success: function(res) {
			console.log('隐藏菜单项成功');
		},
		fail: function(err) {
			console.log('隐藏菜单项失败', err);
		},
	});
}

/**
 * @name requestPaymentH5
 * @description 进行微信h5端的支付
 * @param timestamp // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
 * @param nonceStr // 支付签名随机串，不长于 32 位
 * @param payPackage // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
 * @param signType // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
 * @param paySign // 支付签名
 */
export function requestPaymentH5(timestamp = 0, nonceStr = '', payPackage = '', signType = '', paySign = '') {
	return new Promise((resolve, reject) => {
		wxjssdk.chooseWXPay({
			timestamp,
			nonceStr,
			package: payPackage,
			signType,
			paySign,
			success: function(res) {
				// 支付成功后的回调函数
				resolve(res);
			},
			fail: function(err) {
				//支付失败后的回调函数
				reject(err);
			},
		});
	});
}
//获取回调地址栏上的code信息
export function getUrlParam(url = window.location.href) {
	const params = {};
	const reg = /([^&?]*)=([^&]*)/g;
	console.log('url', url);
	const res = url.match(reg);
	console.log('res', res);
	for (const key in res) {
		const query = res[key].split('=');
		params[query[0]] = query[1];
	}
	return params;
}

// #endif