/*
 * @Date: 2022-10-24 09:17:00
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-24 11:56:33
 * @FilePath: \ulink\src\store\modules\globalConfig.ts
 * @Description:用户详情
 */
import { defineStore } from 'pinia';
export const useGlobalConfigStore = defineStore('globalConfigStore', {
  state: () => {
    return {
      channel: 'default', //渠道
      // sessionId: 'MzU5OTQ', //登录态
      sessionId: '', //登录态
      location: 1, //是否获取定位信息 1:获取,0:不获取
      albumUcoin: 1000, //解锁隐私相册
      adUcoin: 1000, //发布征婚广告
      appointmentUcoin: 1000, //发起约会邀请
      msgTip: '发送色情或其他不良信息的用户，如被举报并核实，其账号将会被封禁，再也不能使用此有恋账号。', //第一次聊天的弹窗
    };
  },
  // actions 用来修改 state
  actions: {},
  unistorage: {
    // 开启后对 state 的数据读写都将持久化
    paths: ['channel', 'sessionId', 'location'], // 需要缓存的路径，
  },
});
export default useGlobalConfigStore;
