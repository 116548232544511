<!--
 * @Date: 2022-10-26 15:13:48
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-28 17:20:19
 * @FilePath: \ulinked\App.vue
 * @Description:
-->
<script setup>
import { storeToRefs } from 'pinia';
import { onMounted, watch, ref } from 'vue';
import { onLaunch, onShow, onHide } from '@dcloudio/uni-app';

import pinia from '@/store/modules/pinia.js';
import useGlobalConfigStore from '@/store/modules/globalConfig.js';
import useAddressStore from '@/store/modules/address.js';
import useUserInfoStore from '@/store/modules/user.js';
import useConversationStore from '@/store/modules/conversation.js';
import useTabbarStore from '@/store/modules/tabar.js';

// #ifdef APP
import { requestAndroidPermission } from '@/lib/utils/Permission.js';
// #endif

const userBaseInfo = storeToRefs(useUserInfoStore(pinia));

import { useInitServer } from '@/server/base.js';
const { initUserInfo } = useInitServer();
// #ifdef H5
import { initConfig, getUrlParam } from '@/lib/Platform/wxH5.js';
// #endif

//#ifdef APP
const jpushModule = uni.requireNativePlugin('JG-JPush');
//#endif
let subscribe = null;

onLaunch(async () => {
  uni.removeStorageSync('shareUserInfo2gather');
  // #ifdef H5
  let url = location.href.split('#')[0];
  uni.setStorageSync('webUrl', url);
  let { title = '' } = getUrlParam();
  document.title = title;
  initConfig();
  // #endif
  initFontFace();
  initJPush();
  // #ifdef MP-WEIXIN
  uni.$platform.updataVersions();
  // #endif

  const globalConfig = storeToRefs(useGlobalConfigStore());
  // #ifdef APP
  globalConfig.channel.value = plus.runtime.channel || 'default';
  // #endif
  // #ifdef MP-WEIXIN
  globalConfig.channel.value = 'miniapp';
  // #endif
  // #ifdef H5
  globalConfig.channel.value = 'h5';
  // #endif
  uni.$api.common.getConfig().then((res) => {
    if (res.code == '0') {
      globalConfig.location.value = res.location;
      globalConfig.albumUcoin.value = res.albumUcoin;
      globalConfig.adUcoin.value = res.adUcoin;
      globalConfig.appointmentUcoin.value = res.appointmentUcoin;
    }
  });
  let openSceen = await uni.$api.common.getSplah();
  // if (openSceen.startupList.length > 0 && isLoginApp) {
  // 	let startupList = encodeURI(JSON.stringify(openSceen.startupList));
  // 	uni.$platform.setOpenScreen(url);
  // 	return;
  // }
  if (globalConfig.sessionId.value != '') {
    initUserInfo();
  }
  useGlobalConfigStore().$subscribe((state) => {
    const { sessionId } = storeToRefs(useGlobalConfigStore());
    console.log('useGlobalConfigStore().sessionId.value ', uni.$u.test.empty(sessionId.value));
    if (!uni.$u.test.empty(sessionId.value)) {
      initUserInfo();
    }
  });
  useAddressStore().$subscribe((state) => {
    console.log('地理位置可以更新了', state);
    let params = {
      ...state.payload,
    };
    uni.$api.common.updateLocaiton({ ...params }).then((query) => {
      if (query.code == 0) {
        initUserInfo();
      }
    });
  });
  // #ifdef APP
  plus.device.getInfo({
    complete() {
      postSys();
    },
  });
  // #endif
  // #ifndef APP
  postSys();
  // #endif

  console.log('App Launch');
});
onShow((options) => {
  init(options);
});
onHide(() => {});

watch(
  () => userBaseInfo.value,
  () => {
    if (userBaseInfo.value.userid != 0) {
      console.log('设置别名  watch', userBaseInfo.value.userid);
      // #ifdef APP
      console.log('new Date().getTime()', new Date().getTime());
      jpushModule.setAlias({
        alias: String(userBaseInfo.value.userid),
        sequence: new Date().getTime(),
      });
      // #endif
    }
  },
);
async function init(optionsOnshow) {
  const globalConfig = storeToRefs(useGlobalConfigStore());

  if (globalConfig.sessionId.value == '') {
    // #ifdef H5
    return;
    let pattern = /(?<=\/#\/).*?(?=[?&])/;
    const match = window.location.href.match(pattern);
    if (match) {
      const result = match[0] || '';
      if (
        [
          'pages/subActivity/common/info',
          'pages/subGather/common/baseInfo',
          'pages/subOther/shareUserInfo/shareUserInfo',
        ].indexOf(result) >= 0
      ) {
        return;
      }
      uni.reLaunch({
        url: `/pages/subLogin/common/preLogin`,
      });
      return;
    }
    // #endif
    //#ifdef MP-WEIXIN
    let options = uni.getLaunchOptionsSync();
    if (options.path != optionsOnshow.path) {
      options = optionsOnshow;
    }
    if (
      [
        'pages/subActivity/common/info',
        'pages/subGather/common/baseInfo',
        'pages/subOther/shareUserInfo/shareUserInfo',
      ].indexOf(options.path) >= 0
    ) {
      return;
    }
    uni.reLaunch({
      url: `/pages/subLogin/common/preLogin`,
    });
    return;
    //#endif
    uni.reLaunch({
      url: `/pages/subLogin/common/preLogin`,
    });
    return;
  }
}
async function postSys() {
  const globalConfig = storeToRefs(useGlobalConfigStore());
  const { longitude, latitude, areaNo, areaName } = storeToRefs(useAddressStore());
  let sys = uni.$u.sys();
  // let ip = '';
  let imei = '';
  let imsi = '';
  let uuid = '';
  // #ifdef APP
  // ip = uni.$platform.getIp();
  imei = plus.device.imei;
  imsi = plus.device.imsi;
  uuid = plus.device.uuid;
  // console.log('plus.device.imei', plus.device.imei);
  // console.log('plus.device.imsi', plus.device.imsi);
  // console.log('plus.device.uuid', plus.device.uuid);
  // #endif

  let params = {
    // ip: ip,
    longitude: longitude.value,
    latitude: latitude.value,
    areaNo: areaNo.value,
    areaName: areaName.value,
    clientType: sys.uniPlatform,
    brand: sys.deviceBrand,
    os: sys.osName,
    ver: sys.oaVersion,
    uuid: uuid,
    imei: imei,
    imsi: imsi,
    appName: sys.appName,
    appVersion: sys.appVersion,
    appVersionCode: sys.appVersionCode,
    appWgtVersion: sys.appWgtVersion,
  };
  console.log('params', params);
  uni.$api.common.recordUserInfo({ ...params });
}
function initJPush() {
  // #ifdef APP
  const globalConfig = storeToRefs(useGlobalConfigStore());

  console.log('jpushModule', jpushModule);
  if (uni.getSystemInfoSync().platform == 'ios') {
    // 请求定位权限
    let locationServicesEnabled = jpushModule.locationServicesEnabled();
    let locationAuthorizationStatus = jpushModule.getLocationAuthorizationStatus();
    console.log('locationAuthorizationStatus', locationAuthorizationStatus);
    if (locationServicesEnabled == true && locationAuthorizationStatus < 3) {
      jpushModule.requestLocationAuthorization((result) => {
        console.log('定位权限', result.status);
      });
    }

    jpushModule.requestNotificationAuthorization((result) => {
      let status = result.status;
      if (status < 2) {
        uni.showToast({
          icon: 'none',
          title: '您还没有打开通知权限',
          duration: 3000,
        });
      }
    });

    jpushModule.addGeofenceListener((result) => {
      let code = result.code;
      let type = result.type;
      let geofenceId = result.geofenceId;
      let userInfo = result.userInfo;
      // uni.showToast({
      // 	icon: 'none',
      // 	title: '触发地理围栏',
      // 	duration: 3000,
      // });
    });
  }
  jpushModule.initJPushService();
  jpushModule.setLoggerEnable(true);
  jpushModule.addConnectEventListener((result) => {
    console.log('addConnectEventListener', result);
    let connectEnable = result.connectEnable;
    if (connectEnable) {
      // console.log(globalConfig.sessionId.value != '');
      if (globalConfig.sessionId.value != '') {
        console.log('userBaseInfo.value.userid', userBaseInfo.value.userid);
        console.log('sequence', new Date().getTime());
        jpushModule.setAlias({
          alias: String(userBaseInfo.value.userid),
          sequence: new Date().getTime(),
        });
      }
      jpushModule.getRegistrationID((result) => {
        let registerID = result.registerID;
        console.log('registerID', registerID);
      });
    }
    uni.$emit('connectStatusChange', connectEnable);
  });

  jpushModule.addNotificationListener((result) => {
    let notificationEventType = result.notificationEventType;
    let messageID = result.messageID;
    let title = result.title;
    let content = result.content;
    let extras = result.extras;
    console.log('addNotificationListener', result);
    // uni.showToast({
    // 	icon: 'none',
    // 	title: JSON.stringify(result),
    // 	duration: 3000,
    // });
  });

  jpushModule.addCustomMessageListener((result) => {
    // console.log('addCustomMessageListener', result);
    let content = '';
    try {
      content = JSON.parse(result.content);
    } catch (e) {
      content = result.content;
      //TODO handle the exception
    }
    // console.log('content', content);
    let type = result.type;
    let messageType = result.messageType;
    onMsg(content);
  });

  jpushModule.addLocalNotificationListener((result) => {
    console.log('addLocalNotificationListener', result);
    let messageID = result.messageID;
    let title = result.title;
    let content = result.content;
    let extras = result.extras;

    // uni.showToast({
    // 	icon: 'none',
    // 	title: JSON.stringify(result),
    // 	duration: 3000,
    // });
  });
  // #endif
}
function onMsg(content) {
  console.log('jpushMsg', content);
  useConversationStore().updateConversation(content);
  console.log('unReadCountNum', useConversationStore().unReadCountNum);
  // #ifdef APP
  useTabbarStore().$patch((state) => {
    state.list[1].badge = useConversationStore().unReadCountNum;
  });
  uni.$emit('onImMessage', content);
  // #endif
}
function initFontFace() {
  // uni.loadFontFace({
  //   global: true,
  //   family: 'MyFont bold',
  //   desc: {
  //     weight: 'bold',
  //   },
  //   source: `url('http://ulinked.oss-cn-shanghai.aliyuncs.com/clientConfig/fontFamily/NotoSansCjkSc/Bold.otf')`,
  //   success(e) {
  //     console.log('initFontFace', e);
  //   },
  // });
  uni.loadFontFace({
    global: true,
    family: 'MyFont normal',
    source: `url('http://ulinked.oss-cn-shanghai.aliyuncs.com/clientConfig/fontFamily/NotoSansCjkSc/Regular.ttf')`,
    success(e) {
      console.log('initFontFace', e);
    },
  });
}
</script>

<style lang="scss">
/*每个页面公共css */
@import 'uview-plus/index.scss';

/* #ifndef APP-NVUE */

page {
  font-family: 'MyFont normal' !important;
}
/* #endif */
/* #ifdef H5 */
uni-modal {
  z-index: 999999 !important;
}
/* #endif */
.pageBg {
  background-image: radial-gradient(circle at 160rpx -150rpx, #ffb9d5 0%, transparent 400rpx),
    radial-gradient(circle at calc(100% - 160rpx) -150rpx, #97dfff 0%, transparent 400rpx);
  background-repeat: no-repeat;
}
</style>
