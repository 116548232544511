import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
const config = {
  thresholds: [{}],
};
dayjs.extend(relativeTime);
uni.$dayjs = dayjs;
export default dayjs;
