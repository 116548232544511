/*
 * @Date: 2022-10-24 09:17:00
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-24 11:46:56
 * @FilePath: \ulink\src\store\modules\conversation.ts
 * @Description:用户详情
 */
import { defineStore } from 'pinia';
import useUserInfoStore from '@/store/modules/user.js';
import useTabbarStore from '@/store/modules/tabar.js';
export const useConversationStore = defineStore('conversationStore', {
  state: () => {
    return {
      currentConversationId: '', //当前会话的id
      conversationList: [], //会话列表
    };
  },
  // actions 用来修改 state
  actions: {
    updateConversation(conversation = null) {
      if (conversation == null) {
        return;
      }
      let _conversationId = conversation.conversationId;
      // console.log('_conversationId', _conversationId);
      let _idx = this.conversationList.findIndex((val) => val.conversationId == _conversationId);
      let _temp = conversation;
      _temp['msgMap'] = {}; //这里默认添加一个空的消息对象
      if (_idx >= 0) {
        _temp = this.conversationList[_idx];
        let uId = useUserInfoStore().userBaseInfo.userid;
        if (_temp.lastMessage.fromAccount != uId) {
          _temp.unreadCount += 1;
        }
        _temp.lastMessage = conversation.lastMessage;
        _temp.lastTime = conversation.lastTime;
        _temp.extra = conversation.extra;
      }
      Object.assign(_temp.msgMap, {
        [conversation.lastMessage.msgId]: conversation.lastMessage,
      });
      if (_idx >= 0) {
        this.conversationList[_idx] = _temp;
      } else {
        this.conversationList.push(_temp);
      }
    },
    setMsgPeerRead(conversationId = '', msgId = '') {
      if (conversationId == '' || msgId == '') {
        return;
      }
      let idx = this.conversationList.findIndex((val) => val.conversationId == conversationId);
      if (idx == -1) {
        return;
      }
      let temp = uni.$u.deepClone(this.conversationList[idx]);
      temp.unreadCount = 0;
      temp.msgMap[msgId].isPeerRead = true;
      this.conversationList[idx] = temp;
      console.log('this.conversationList', this.conversationList);
      // #ifdef APP
      useTabbarStore().$patch((state) => {
        state.list[1].badge = this.unReadCountNum;
      });
      // #endif
    },
    setPeerRead(conversationId = '') {
      if (conversationId == '') {
        return;
      }

      let idx = this.conversationList.findIndex((val) => val.conversationId == conversationId);
      if (idx == -1) {
        return;
      }
      let temp = uni.$u.deepClone(this.conversationList[idx]);
      temp.unreadCount = 0;
      for (let key in temp.msgMap) {
        temp.msgMap[key].isPeerRead = true;
      }
      this.conversationList[idx] = temp;
      // #ifdef APP
      useTabbarStore().$patch((state) => {
        state.list[1].badge = this.unReadCountNum;
      });
      // #endif
    },
  },
  getters: {
    currentConversation({ conversationList, currentConversationId }) {
      let obj = {
        conversationType: 'C2C',
      };
      conversationList.map((val) => {
        if (val.conversationId == currentConversationId) {
          obj = uni.$u.deepClone(val);
        }
      });
      return obj;
    },
    unReadCountNum({ conversationList }) {
      let count = 0;
      // console.log('conversationList', conversationList);
      conversationList.map((val) => {
        if (val.msgMap) {
          Object.values(val.msgMap).map((val1) => {
            // console.log('消息未读：', !val1.isPeerRead, val1.fromAccount != useUserInfoStore().userBaseInfo.userid);
            if (!val1.isPeerRead && val1.fromAccount != useUserInfoStore().userBaseInfo.userid) {
              //如果未读 未读数量加1
              count += 1;
            }
          });
        }
      });
      // console.log(' conversation count', count);
      return count;
    },
    currentMsgList() {
      let list = [];
      if (this.currentConversation?.msgMap) {
        list = Object.values(this.currentConversation.msgMap);
        list = list.sort((a, b) => a.lastTime < b.lastTime);
      }
      return list;
    },
  },
  unistorage: {
    // 开启后对 state 的数据读写都将持久化
    paths: ['conversationList'], // 需要缓存的路径，
  },
});
export default useConversationStore;
