/*
 * @Date: 2022-10-28 10:45:51
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-28 10:56:00
 * @FilePath: \ulinked\lib\Platform\config.js
 * @Description:
 */
export const baiduMapKey = 'hHDr6wI4nrhQp1AfPXrjextpUH15ihtz';

// #ifdef APP-PLUS
export const config = {
	//android 权限配置
	permission: {
		userLocation: {
			ios: 'location',
			as: 'android.permission.ACCESS_FINE_LOCATION',
			title: '·申请获取您的地理位置',
			opentype: '',
		},
		userFuzzyLocation: {
			ios: 'location',
			as: 'android.permission.ACCESS_COARSE_LOCATION',
			title: '·申请获取您的地理位置',
			opentype: '',
		},
		record: {
			ios: 'record',
			as: 'android.permission.RECORD_AUDIO',
			title: '·申请获取您的录音功能',
			opentype: '',
		},
		getPhotosAlbum: {
			ios: 'photoLibrary',
			as: 'android.permission.READ_EXTERNAL_STORAGE', //读取权限
			title: '·申请获取您的相册权限',
			opentype: '',
		},
		writePhotosAlbum: {
			ios: '',
			as: 'android.permission.WRITE_EXTERNAL_STORAGE', //写入权限
			title: '·申请获取您的相册权限',
			opentype: '',
		},
		camera: {
			ios: 'camera',
			as: 'android.permission.CAMERA',
			title: '·申请获取您的摄像机权限',
			opentype: '',
		},
		push: {
			ios: 'push',
			as: '',
			title: '·申请获取您的推送权限',
			opentype: '',
		},
		readContact: {
			ios: 'contact',
			as: 'android.permission.READ_CONTACTS', //读写权限
			title: '·申请获取您的通讯录权限',
			opentype: '',
		},
		writeContact: {
			ios: 'contact',
			as: 'android.permission.WRITE_CONTACTS',
			title: '·申请获取您的通讯录写入权限',
			opentype: '',
		},
		smsRead: {
			ios: '',
			as: 'android.permission.READ_SMS', //短信读取
			title: '·申请获取您的短信权限',
			opentype: '',
		},
		smsSend: {
			ios: '',
			as: 'android.permission.SEND_SMS', //短信发送
			title: '·申请获取您的短信发送权限',
			opentype: '',
		},
		smsReceive: {
			ios: '',
			as: 'android.permission.RECEIVE_SMS', //短信接收的权限
			title: '·申请获取您的短信接收权限',
			opentype: '',
		},
		phoneState: {
			ios: '',
			as: 'android.permission.READ_PHONE_STATE', //获取手机识别码
			title: '·申请获取您的手机识别码',
			opentype: '',
		},
		callPhone: {
			ios: '',
			as: 'android.permission.CALL_PHONE', //拨打电话的权限
			title: '·申请获取您拨打电话的权限',
			opentype: '',
		},
		readCallLog: {
			ios: '',
			as: 'android.permission.READ_CALL_LOG', //通话记录
			title: '·申请获取您通话记录的权限',
			opentype: '',
		},
	},
};
// #endif
//#ifdef MP-WEIXIN || H5
export const config = {
	//微信小程序权限配置
	permission: {
		userInfo: {
			scope: 'scope.userInfo',
			title: '·申请获取您的用户信息',
			opentype: '',
		},
		userLocation: {
			scope: 'scope.userLocation',
			title: '·申请获取您的地理位置',
			opentype: '',
		},
		userLocationBackground: {
			scope: 'scope.userLocationBackground',
			title: '·申请后台定位您的地理位置',
			opentype: '',
		},
		address: {
			scope: 'scope.address',
			title: '·申请获取您的通讯地址',
			opentype: '',
		},
		invoiceTitle: {
			scope: 'scope.invoiceTitle',
			title: '·申请获取您的发票抬头',
			opentype: '',
		},
		invoice: {
			scope: 'scope.invoice',
			title: '·申请获取您的发票信息',
			opentype: '',
		},
		werun: {
			scope: 'scope.userInfo',
			title: '·申请获取您的微信运动步数',
			opentype: '',
		},
		record: {
			scope: 'scope.record',
			title: '·申请获取您的录音功能',
			opentype: '',
		},
		writePhotosAlbum: {
			scope: 'scope.writePhotosAlbum',
			title: '·申请获取您的相册权限',
			opentype: '',
		},
		camera: {
			scope: 'scope.writePhotosAlbum',
			title: '·申请获取您的摄像机权限',
			opentype: '',
		},
	},
};
// #endif
