import { ref, reactive, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { getAreaItem } from '@/lib/Enum/index.js';

import useUserInfoStore from '@/store/modules/user.js';
import useGlobalConfigStore from '@/store/modules/globalConfig.js';
import useAddressStore from '@/store/modules/address.js';

import pinia from '@/store/modules/pinia.js';

// #ifdef H5
import { initConfig, loginWxh5, getUrlParam } from '@/lib/Platform/wxH5.js';
// #endif

const { userBaseInfo, userExtInfo } = storeToRefs(useUserInfoStore(pinia));
const {
  areaNo,
  areaName, //城市名称默认北京市
  longitude, //经度
  latitude, //纬度
} = storeToRefs(useAddressStore(pinia));
const { sessionId, location, channel } = storeToRefs(useGlobalConfigStore(pinia));
export function useAddressServer() {
  //定位服务管理
  const address = reactive({
    areaNo: areaNo.value, //城市码
    areaName: areaName.value, //城市名称默认北京市
    longitude: longitude.value, //经度
    latitude: latitude.value, //纬度
  });
  const initLocation = async () => {
    if (location.value != 1) {
      return;
    }
    let isLocationPm = await uni.$platform.checkPm('userLocation');
    // console.log('isLocationPm', isLocationPm);
    if (!isLocationPm.code) {
      let isGoPermission = await uni.showModal({
        title: '温馨提示',
        content: '为了给您提供更好的服务，我们需要您授权获取定位权限',
      });
      if (!isGoPermission.confirm) {
        return;
      }
      let hasPm = await uni.$platform.getPm('userLocation');
      // console.log('hasPm', hasPm);
      if (hasPm != 1) {
        return;
      }
    }
    let _address = await uni.$platform.getLocation();
    useAddressStore().$patch({
      longitude: _address.longitude,
      latitude: _address.latitude,
      areaName: _address.city,
      areaNo: getAreaItem(_address.city, 'value').code,
    });
  };
  return {
    getLocation,
  };
}
export function useLoginServer() {
  const address = reactive({
    areaNo: areaNo.value, //城市码
    areaName: areaName.value, //城市名称默认北京市
    longitude: longitude.value, //经度
    latitude: latitude.value, //纬度
  });
  const loginParams = reactive({
    admin: '',
    password: '',
    code: '',
  });
  const pwdLogin = () => {
    let resMsg = {
      code: -1,
      message: '登录失败',
      data: null,
    };

    return new Promise(async (resolve, reject) => {
      let query = await uni.$api.user.login({
        userid: loginParams.admin,
        password: loginParams.password,
        ...address,
      });
      resMsg.code = query.code;
      resMsg.message = query.message;
      resMsg.data = query;
      if (query.hasOwnProperty('sessionId')) {
        useGlobalConfigStore().$patch((state) => {
          state.sessionId = query.sessionId;
        });
      }
      if (resMsg.code != '0') {
        reject(resMsg);
        return;
      }
      resolve(resMsg);
    });
  };
  const wxLogin = () => {
    let resMsg = {
      code: -1,
      message: '登录失败',
      data: null,
    };
    return new Promise(async (resolve, reject) => {
      // #ifndef H5
      let code = await uni.login({
        // #ifdef APP
        onlyAuthorize: true, // 微信登录仅请求授权认证
        // #endif
        provider: 'weixin',
      });
      if (code.errMsg != 'login:ok') {
        resMsg = {
          code: -1,
          message: '微信登录失败',
        };
      }
      loginParams.code = code.code;
      // #endif
      // #ifdef MP-WEIXIN
      let query = await uni.$api.user.loginWxMini({
        code: loginParams.code,
        ...address,
      });
      // #endif
      // #ifdef APP
      console.log('code', loginParams);
      let query = await uni.$api.user.loginWxApp({
        code: loginParams.code,
        ...address,
      });
      console.log('query', query);
      // #endif
      // #ifdef H5

      let code = getUrlParam()?.code || ''; //是否存在code
      if (code == null || code == '' || code == undefined) {
        loginWxh5();
        return;
      }
      let query = await uni.$api.user.loginWxH5({
        code: code,
        ...address,
      });
      console.log('query', query);
      // #endif
      resMsg.code = query.code;
      resMsg.message = query.message;
      resMsg.data = query;
      if (query.hasOwnProperty('sessionId')) {
        useGlobalConfigStore().$patch((state) => {
          state.sessionId = query.sessionId;
        });
      }
      if (resMsg.code != '0') {
        reject(resMsg);
        return;
      }
      resolve(resMsg);
    });
  };
  const bindWx = () => {
    let resMsg = {
      code: -1,
      message: '绑定失败',
      data: null,
    };
    return new Promise(async (resolve, reject) => {
      let code = await uni.login({
        provider: 'weixin',
      });
      if (code.errMsg != 'login:ok') {
        resMsg = {
          code: -1,
          message: '微信登录失败',
        };
      }
      code = code.code;
      // #ifdef MP-WEIXIN
      let query = await uni.$api.user.bindMiniApp({
        code: code,
        ...address,
      });
      // #endif
      // #ifdef APP
      let query = await uni.$api.user.bindWx({
        code: code,
        ...address,
      });
      // #endif
      resMsg.code = query.code;
      resMsg.message = query.message;
      resMsg.data = query;
      if (resMsg.code != '0') {
        reject(resMsg);
        return;
      }
      resolve(resMsg);
    });
  };
  return {
    address,
    loginParams,
    pwdLogin,
    wxLogin,
    bindWx,
  };
}
export function useRegisterServer() {
  const address = reactive({
    areaNo: areaNo.value, //城市码
    areaName: areaName.value, //城市名称默认北京市
    longitude: longitude.value, //经度
    latitude: latitude.value, //纬度
  });
  const registerInfo = reactive({
    sex: '-1', //性别 0男 1女
    age: '24', //默认24岁
    openId: '', //开放id
    unionId: '', //唯一id
    hongniangSessionId: '', //分享的红娘的id
    activityId: '', //分享的活动的id
  });
  const wxRegister = () => {
    let resMsg = {
      code: -1,
      message: '登录失败',
      data: null,
    };
    return new Promise(async (resolve, reject) => {
      // #ifdef MP-WEIXIN
      let query = await uni.$api.user.registerWxMini({
        ...address,
        ...registerInfo,
      });
      // #endif
      // #ifdef APP
      let query = await uni.$api.user.registerWxApp({
        ...address,
        ...registerInfo,
      });
      // #endif
      // #ifdef H5
      let query = await uni.$api.user.registerWxH5({
        ...address,
        ...registerInfo,
      });
      // #endif
      resMsg.code = query.code;
      resMsg.message = query.message;
      resMsg.data = query;
      if (query.hasOwnProperty('sessionId')) {
        useGlobalConfigStore().$patch((state) => {
          state.sessionId = query.sessionId;
        });
      }
      if (resMsg.code != '0') {
        reject(resMsg);
        return;
      }
      resolve(resMsg);
    });
  };

  return {
    registerInfo,
    wxRegister,
  };
}

export function useMobileServer() {
  const phoneNum = ref('');
  const getWxMiniMobile = (e) => {
    return new Promise(async (resolve, reject) => {
      let phoneCode = e.detail.code;
      let wxLogin = await uni.login({
        provider: 'weixin',
      });
      console.log('wxLogin', wxLogin);
      let query = await uni.$api.common.getMiniAppPhone({
        phoneCode,
      });
      if (query.code != '0') {
        uni.$u.toast('手机号获取失败请重新获取', 2000);
        reject(false);
        return;
      }
      phoneNum.value = query.phone;
      resolve(true);
    });
  };
  const bindMobile = async () => {
    let query = await uni.$api.user.bindMobile({
      phone: phoneNum.value,
    });
    if (query.code != '0') {
      uni.$u.toast(query.message, 2000);
      return false;
    }
    return true;
  };
  return {
    phoneNum,
    getWxMiniMobile,
    bindMobile,
  };
}

export function useInitServer() {
  const initUserInfo = () => {
    useUserInfoStore().getMyInformation();
    useUserInfoStore().getHobbyInfo();
    useUserInfoStore().getHeartVoice();
    useUserInfoStore().getMyAlbum();
    useUserInfoStore().getMemberInfo();
    useUserInfoStore().getMateSelection();
    useUserInfoStore().getMyPageInfo();
    Promise.resolve();
  };
  return {
    initUserInfo,
  };
}
