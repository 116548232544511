/*
 * @Date: 2022-10-24 09:17:00
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-24 11:43:25
 * @FilePath: \ulink\src\store\modules\user.ts
 * @Description:用户详情
 */
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
const config = {
  thresholds: [{}],
};
dayjs.extend(relativeTime);
export const useUserInfoStore = defineStore(
  'userInfoStore',
  {
    state() {
      return {
        status: 0, //用户状态0:待审核,1:审核通过,2:冻结,4:审核拒绝,9:已注销
        //我的资料
        myInformation: {
          userInfo: {
            userid: 0,
            headUrl: '',
            httpHeadUrl: '',
            sex: -1,
            nickName: '',
            age: 24,
            birthday: dayjs()?.subtract(24, 'year').format('YYYY-MM-DD'),
            longitude: '116.2',
            latitude: '39.56',
            areaNo: '110001', //所在地区
            areaName: '北京市', //所在地区
            height: 0,
            weight: 0,
            income: 0,
            hukou: '', //户口
            profession: 0, //职业
            edu: 0, //学历
            isVip: 0,
            memberType: 0,
            phoneAuth: 0,
            weixinAuth: 0,
            role: 0, //0 普通用户1红娘
            merchantName: '', //红娘所在的商家
            merchantLogo: '',
            phone: '', //手机号码
            name: '', //姓名
          },
          userExtInfo: {
            phone: '', //用户绑定的手机号码
            nation: 0, //民族
            marry: 0, //婚姻状况
            marryTime: 0, //何时结婚
            child: 0, //有没有小孩
            blood: 0, //血型
            smoke: 0, //是否吸烟
            drink: 0, //是否喝酒
            weixin: '', //微信
            qq: '', //qq号码
            address: '', //详细地址
            house: 0, //房屋情况
            car: 0, //购车情况
            familySituation: '', //家庭情况
            jiguan: '', //籍贯
            star: 1, //星座
            religion: 1, //宗教
          },
        },
        //我的相册
        myAlbum: {
          // {
          // 	albumId: 0,//照片id
          // 	urlName: '', //图片名称
          // 	httpUrl: '', //网络地址
          // 	isPm: '', //是否设置隐私 0否1是
          // 	status: ''//状态0:待审核,1:审核通过,4:审核拒绝
          // }
          //我的动态
          dynamic: [],
          //我的小窝
          home: [],
          //个人粉彩
          self: [],
        },
        //兴趣爱好
        hobbyInfo: {
          foods: '',
          hobbies: '',
          sports: '',
        },
        //交友想法
        makingFriendsIdea: {
          content: '',
          status: -1, //0:待审核,1:审核通过,4:审核拒绝
        },
        //会员中心
        memberInfo: {
          userid: 0,
          httpHeadUrl: '',
          nickName: '',
          sex: 0,
          age: 24,
          edu: 0,
          profession: 3,
          areaName: '',
          expireTime: '',
          phoneAuth: 0,
          weixinAuth: 0,
          ucoin: 0,
          isVip: 0,
          memberType: 0,
          merchantName: '', //红娘所在的商家
          merchantLogo: '', //红娘所在商家的logo
        },
        //我的页面详情
        myInfo: {
          memberType: 0,
          accessCount: 0, //访客人数
          collectMeCount: 0, //收藏我的人数
          iCollectCount: 0, //我收藏的人数
        },
        //择偶标准
        mateSelection: {
          hukou: '', //户口
          areaCode: '', //所在地
          carScope: '0',
          childScope: '0',
          drinkScope: '0',
          eduScope: '0',
          ageScope: '',
          heightScope: '',
          weightScope: '',
          houseScope: '0',
          incomeScope: '0',
          marryScope: '0',
          nationScope: '0',
          professionScope: '0',
          smokeScope: '0',
          other: '', //其他
        },
      };
    },
    getters: {
      userMobile() {
        return this.myInformation.userExtInfo.phone;
      },
      userUcoin() {
        return this.memberInfo.ucoin || 0;
      },
      userBaseInfo() {
        return this.myInformation.userInfo;
      },
      userExtInfo() {
        return this.myInformation.userExtInfo;
      },
    },
    // actions 用来修改 state
    actions: {
      async getMyInformation() {
        let query = await uni.$api.user.myInformation.getUserInfo();
        if (query.code == '0') {
          this.myInformation.userInfo = uni.$u.deepMerge(this.myInformation.userInfo, query.userInfo);
          this.myInformation.userExtInfo = uni.$u.deepMerge(this.myInformation.userExtInfo, query.userExtInfo);
        } else {
          uni.$u.toast(query.message, 2000);
        }
      },
      async updateMyInformation(info) {
        let query = await uni.$api.user.myInformation.updateUserInfo({
          ...info,
        });
        if (query.code == '0') {
          this.myInformation = uni.$u.deepMerge(this.myInformation, info);
        }
        return query;
      },
      async getHobbyInfo() {
        let query = await uni.$api.user.myInformation.getHobbyInfo();
        if (query.code == '0') {
          this.hobbyInfo = uni.$u.deepMerge(this.hobbyInfo, query.userHobbyInfo);
        }
        return query;
      },
      async updateHobbyInfo(params) {
        let query = await uni.$api.user.myInformation.updateHobbyInfo({
          ...params,
        });
        if (query.code == '0') {
          this.hobbyInfo = uni.$u.deepMerge(this.hobbyInfo, params.userHobbyInfo);
        }
        return query;
      },
      async getHeartVoice() {
        let query = await uni.$api.user.myInformation.getHeartVoice();
        if (query.code == '0') {
          this.makingFriendsIdea = {
            content: query?.content,
            status: query?.status,
          };
        }
        return query;
      },
      async updateHeartVoice(params) {
        let query = await uni.$api.user.myInformation.updateHeartVoice({
          ...params,
        });
        if (query.code == '0') {
          this.makingFriendsIdea = {
            content: params.content,
            status: 0,
          };
        }
        return query;
      },
      async getMyAlbum() {
        let query = await uni.$api.user.myAlbum.getList();
        if (query.code == '0') {
          this.myAlbum.self = query.self || [];
          this.myAlbum.home = query.home || [];
          this.myAlbum.dynamic = query.dynamic || [];
        }
        return query;
      },
      async getMateSelection() {
        let query = await uni.$api.user.mateSelection.get();
        if (query.code == '0') {
          this.mateSelection = uni.$u.deepMerge(this.mateSelection, query.userSelectInfo);
        }
      },
      async updateMateSelection(params) {
        let query = await uni.$api.user.mateSelection.update({
          userSelectInfo: params,
        });
        if (query.code == '0') {
          this.mateSelection = uni.$u.deepMerge(this.mateSelection, params);
        }
        return query;
      },
      async getMemberInfo() {
        let query = await uni.$api.user.getMemberInfo();
        if (query.code == '0') {
          this.memberInfo = uni.$u.deepMerge(this.memberInfo, query.userInfo);
        }
        return query;
      },
      //获取我的页面 数据
      async getMyPageInfo() {
        let query = await uni.$api.user.getMyPageInfo();
        if (query.code == '0') {
          this.myInfo = uni.$u.deepMerge(this.myInfo, query.userInfo);
        }
        return query;
      },
    },
  },
  {
    unistorage: {
      // 开启后对 state 的数据读写都将持久化
      paths: ['myInformation', 'myAlbum', 'hobbyInfo', 'makingFriendsIdea', 'memberInfo', 'myInfo', 'mateSelection'], // 需要缓存的路径，
    },
  },
);
export default useUserInfoStore;
