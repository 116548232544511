/*
 * @Date: 2022-10-28 10:45:44
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-28 16:06:31
 * @FilePath: \ulinked\lib\Platform\index.js
 * @Description:
 */
import { config, baiduMapKey } from './config.js';

// #ifdef MP-WEIXIN
import { BMapWX } from './bmap-wx.js';
// #endif

// #ifdef APP
import * as permission from '../utils/Permission.js';
isIos = plus.os.name == 'iOS';
// #endif
var isIos;

export default {
  config,
  /**
   * 校验是否授权
   * @param {Object} name config里面的权限名称
   */
  async checkPm(name) {
    let pm = config.permission[name];
    let pmRes = -1;
    // #ifdef APP
    if (plus.os.name == 'iOS') {
      pmRes = await permission.judgeIosPermission(pm.ios);
      console.log('ios pmRes', pmRes);
      return {
        code: pmRes ? 1 : 0,
        message: pm.title + (pmRes ? '成功' : '失败'),
      };
    } else {
      let main = plus.android.runtimeMainActivity();
      let ContextCompat = plus.android.importClass('androidx.core.content.ContextCompat');
      pmRes = ContextCompat.checkSelfPermission(main, pm.as); //0 授权 其他未授权
    }
    // #endif
    // #ifdef MP-WEIXIN
    let authList = await uni.getSetting();
    let _list = [];
    pmRes = !!Reflect.getOwnPropertyDescriptor(authList?.authSetting, pm.scope) ? 0 : -1;
    // #endif
    return {
      code: pmRes != 0 ? 0 : 1,
      message: pm.title + (pmRes != 0 ? '失败' : '成功'),
    };
  },
  /**
   * 获取指定权限
   * @param {Object} name
   */
  async getPm(name) {
    let pm = config.permission[name];
    let pmRes = -1;
    // #ifdef APP
    if (plus.os.name == 'iOS') {
      pmRes = await permission.judgeIosPermission(pm.ios);
      console.log('ios pmRes', pmRes);
      return {
        code: pmRes ? 1 : 0,
        message: pm.title + (pmRes ? '成功' : '失败'),
      };
    } else {
      let code = await permission.requestAndroidPermission(pm.as);
      console.log('code', code);
      if (code == 1) {
        pmRes = 1; //已获得授权
      } else if (code == 0) {
        pmRes = 0; //未获得授权
      } else {
        pmRes = -1; //永久拒绝
        this.openSetting();
      }
    }
    // #endif
    // #ifdef MP-WEIXIN
    await uni.authorize({
      scope: pm.scope,
      success() {
        pmRes = 1;
      },
      fail() {
        pmRes = 0;
      },
    });
    // #endif
    return {
      code: pmRes,
      message: pm.title + (pmRes == 1 ? '成功' : '失败'),
    };
  },
  /**
   * 权限设置页面
   */
  async openSetting() {
    let isGoSettingPage = await uni.showModal({
      title: '温馨提示',
      content: '是否前往设置中心?',
    });
    if (!isGoSettingPage.confirm) {
      return;
    }
    if (isGoSettingPage.confirm) {
      uni.openAppAuthorizeSetting({
        success(res) {
          console.log(res);
        },
      });
    }
  },
  /**
   * 打开安卓app网络设置页面
   */
  openNetworkSetting() {
    var main = plus.android.runtimeMainActivity(); //获取activity
    var Intent = plus.android.importClass('android.content.Intent');
    var Settings = plus.android.importClass('android.provider.Settings');
    var intent = new Intent(Settings.ACTION_WIRELESS_SETTINGS); //可设置http://ask.dcloud.net.cn/question/14732这里所有Action字段
    main.startActivity(intent);
  },
  /*
		检查小程序更新
	 */
  updataVersions() {
    if (uni.canIUse('getUpdateManager')) {
      // 判断当前微信版本是够支持版本更新
      const updateManager = uni.getUpdateManager();
      updateManager.onCheckForUpdate(function (res) {
        if (res.hasUpdate) {
          // 请求完新版本信息的回调
          updateManager.onUpdateReady(function () {
            uni.showModal({
              title: '更新提示',
              content: '新版本已经准备好，请您更新',
              showCancel: false,
              success(res) {
                if (res.confirm) {
                  // 新的版本已经下载好，调用 applyUpdate 应用新版本并重启
                  updateManager.applyUpdate();
                }
              },
            });
          });
          updateManager.onUpdateFailed(function () {
            uni.showModal({
              // 新版本下载失败
              title: '已经有新版本了~',
              content: '新版本已经上线了，请您删除当前小程序，重新搜索打开体验新版本',
            });
          });
        }
      });
    }
  },
  /**
   * 下载图片到本地
   * @param {Object} imgUrl	图片的网络地址
   */
  saveImageToPhotosAlbum(imgUrl) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.checkPm('writePhotosAlbum').then(() => {
        console.log(imgUrl);
        uni.getImageInfo({
          src: imgUrl,
          success: function (res) {
            uni.saveImageToPhotosAlbum({
              filePath: res.path,
              success: function () {
                uni.showToast({
                  icon: 'none',
                  title: '保存成功',
                  duration: 2000,
                });
              },
              fail: function () {
                uni.showToast({
                  icon: 'none',
                  title: '保存失败',
                  duration: 2000,
                });
              },
            });
          },
        });
      });
    });
  },
  /**
   * 拨打电话
   * @param {Boolean} showToast 是否展示拨打电话前的弹窗
   * @param {String} title	弹窗标题
   * @param {String} content	弹窗描述
   * @param {Number,String} mobile	电话号码
   */
  async makePhoneCall({ showToast = true, title, content, mobile }) {
    if (showToast) {
      let isShowModal = await uni.showModal({
        title: title || '温馨提示',
        content: content || '是否拨打客服电话' + mobile,
      });
      if (isShowModal.cancel) {
        return;
      }
    }
    /* #ifdef APP-PLUS */
    let iscall = await this.checkPm('callPhone');
    if (!iscall.code) {
      let isGoPermission = await uni.showModal({
        title: '温馨提示',
        content: '我们需要获取您拨打电话的权限',
      });
      if (!isGoPermission.confirm) {
        return;
      }
      let hasPm = await this.getPm('callPhone');
      console.log('hasPm', hasPm);
      if (hasPm != 1) {
        return;
      }
    }
    /* #endif */
    uni.makePhoneCall({
      phoneNumber: String(mobile), //仅为示例
    });
  },
  /**
   * 设置开屏的网站
   * @param {String} url 网址链接 如果不存在那么将直接进入
   * @param {String} zindex 层级
   * @param {String} timer 多长时间后自动进入 默认6000ms
   */
  setOpenScreen({ url = '', zindex = 999999, timer = 6000 }) {
    let sys = uni.getSystemInfoSync();
    //app启动时打开启动广告页
    if (url == '') {
      return;
    }
    plus.navigator.setFullscreen(false);
    let o = plus.webview.open(
      url,
      'advertise',
      {
        top: -1 * sys.safeAreaInsets.top + 'px',
        bottom: -1 * sys.safeAreaInsets.bottom + 'px',
        zindex: zindex,
      },
      'fade-in',
      0,
    );
    //设置定时器，timer后关闭启动广告页
    setTimeout(function () {
      plus.webview.close(o);
      plus.navigator.setFullscreen(true);
    }, timer);
  },

  getLocation() {
    console.log('获取地理位置');
    return new Promise((resolve, reject) => {
      uni.showLoading({
        icon: 'loading',
        title: '定位中...',
      });
      // #ifdef MP-WEIXIN
      let baiduMap = new BMapWX({
        ak: baiduMapKey,
      });
      console.log('baiduMap', baiduMap);
      baiduMap.regeocoding({
        success: (res) => {
          console.log('res', res);
          let {
            location: { lat = 39.56, lng = 116.2 },
            addressComponent: { city = '北京市', province = '通州区' },
          } = res.originalData.result;
          resolve({
            latitude: lat,
            longitude: lng,
            city,
            province,
          });
          uni.hideLoading();
        },
        fail: () => {
          uni.hideLoading();
        },
      });
      // #endif

      // uni.getLocation({
      // 	type: 'gcj02',
      // 	altitude: true,
      // 	// #ifdef APP
      // 	geocode: true,
      // 	// #endif
      // 	success(res) {
      // 		console.log('location success', res);

      // 		resolve({
      // 			latitude: res.latitude,
      // 			longitude: res.longitude,
      // 		});
      // 	},
      // 	fail(err) {
      // 		console.log('location err', err);
      // 	},
      // 	complete() {
      // 		uni.hideLoading();
      // 	},
      // });
    });
  },
  getIp() {
    var ip = '127.0.0.1';
    var Context = plus.android.importClass('android.content.Context');
    if (plus.os.name == 'Android') {
      //WifiManager
      var Context = plus.android.importClass('android.content.Context');
      var WifiManager = plus.android.importClass('android.net.wifi.WifiManager');
      var wifiManager = plus.android.runtimeMainActivity().getSystemService(Context.WIFI_SERVICE);
      var WifiInfo = plus.android.importClass('android.net.wifi.WifiInfo');

      if (!wifiManager.isWifiEnabled()) {
        //未连接wifi
        var Enumeration = plus.android.importClass('java.util.Enumeration');
        var NetworkInterface = plus.android.importClass('java.net.NetworkInterface');
        var Inet4Address = plus.android.importClass('java.net.Inet4Address');
        var en = NetworkInterface.getNetworkInterfaces();
        while (plus.android.invoke(en, 'hasMoreElements')) {
          var ni = plus.android.invoke(en, 'nextElement');
          var interfaceName = ni.getDisplayName();
          //console.log("tag", "网络名字" + interfaceName);
          var enumIpAddr = plus.android.invoke(ni, 'getInetAddresses');
          while (plus.android.invoke(enumIpAddr, 'hasMoreElements')) {
            var inetAddress = plus.android.invoke(enumIpAddr, 'nextElement');
            if (!plus.android.invoke(inetAddress, 'isLoopbackAddress') && inetAddress instanceof Inet4Address) {
              ip = plus.android.invoke(inetAddress, 'getHostAddress');
              return ip;
            }
          }
        }
      } else {
        //连接wifi
        var wifiInfo = wifiManager.getConnectionInfo();
        ip = this.intToIp(wifiInfo.getIpAddress());
        var wifiInfo = wifiManager.getConnectionInfo();
        var ipAddress = wifiInfo.getIpAddress();
        ip = this.intToIp(ipAddress);
      }
    }
    return ip;
  },
  intToIp(i) {
    return (i & 0xff) + '.' + ((i >> 8) & 0xff) + '.' + ((i >> 16) & 0xff) + '.' + ((i >> 24) & 0xff);
  },
};
