/*
 * @Date: 2022-10-27 16:22:01
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-27 16:54:34
 * @FilePath: \ulinked\lib\request\index.js
 * @Description:
 */
// 引入配置
import {
	config
} from '@/common/config.js';
// 引入拦截器配置
import {
	requestInterceptors,
	responseInterceptors
} from './interceptors.js';
//  初始化请求配置
const initRequest = (vm) => {
	uni.$u.http.setConfig((defaultConfig) => {
		/* defaultConfig 为默认全局配置 */
		defaultConfig.baseURL = config.baseUrl; /* 根域名 */
		defaultConfig.custom = {
			toast: false,
			catch: true
		};
		return defaultConfig;
	});
	requestInterceptors();
	responseInterceptors();
};
export {
	initRequest
};
