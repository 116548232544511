/*
 * @Date: 2022-10-27 16:22:01
 * @LastEditors: 李鹏帅 916709059@qq.com
 * @LastEditTime: 2022-10-28 10:37:03
 * @FilePath: \ulinked\lib\request\interceptors.js
 * @Description:
 */
import { storeToRefs } from 'pinia';
// #ifdef H5
import { closeWindow } from '@/lib/Platform/wxH5.js';
// #endif

import useGlobalConfigStore from '@/store/modules/globalConfig.js';
import useUserInfoStore from '../../store/modules/user';

let isShowModal = false;
const unNeedToken = [
  '/app/queryStartupList',
  '/app/upgrade',
  '/app/config',
  '/app/getJsapiInfo',
  '/user/register',
  '/user/miniAppRegister',
  '/user/appRegister',
  '/user/h5Register',
  '/user/login',
  '/user/miniAppLogin',
  '/user/appLogin',
  '/user/h5Login',
  '/code/send',
  '/code/verify',
  '/user/updatePassword',
  '/homePage/queryBannerList',
  '/homePage/queryAdList',
  '/homePage/queryNewUserList',
  '/homePage/queryRemdUserList',
  '/homePage/searchUserList',
  '/homePage/queryUserDetail',
  '/activity/queryDetail',
];
const requestInterceptors = (vm) => {
  /**
   * 请求拦截
   * @param {Object} http
   */
  uni.$u.http.interceptors.request.use(
    (config) => {
      const globalConfig = storeToRefs(useGlobalConfigStore());
      // 可使用async await 做异步操作
      // 初始化请求拦截器时，会执行此方法，此时data为undefined，赋予默认{}
      if (config.method == 'POST') {
        config.data = {
          ...config.data,
          channel: globalConfig.channel.value,
          sessionId: globalConfig.sessionId.value,
        };
      }

      if (config.method == 'UPLOAD') {
        config.header = {
          ...config.header,
          channel: globalConfig.channel.value,
          sessionId: globalConfig.sessionId.value,
        };
        config.formData = {
          channel: globalConfig.channel.value,
          sessionId: globalConfig.sessionId.value,
        };
      }
      // 可以在此通过vm引用vuex中的变量，具体值在vm.$store.state中
      // console.log(vm.$store.state);
      return config;
    },
    (
      config, // 可使用async await 做异步操作
    ) => Promise.reject(config),
  );
};
const responseInterceptors = (vm) => {
  /**
   * 响应拦截
   * @param {Object} http
   */
  uni.$u.http.interceptors.response.use(
    async (response) => {
      const globalConfig = storeToRefs(useGlobalConfigStore());
      /* 对响应成功做点什么 可使用async await 做异步操作*/
      const data = response.data;
      // 自定义参数
      const custom = response.config?.custom;

      if (data.code != 0) {
        // 如果没有显式定义custom的toast参数为false的话，默认对报错进行toast弹出提示

        if (custom.toast !== false) {
          // 0：成功，1：所有逻辑的错误，9：业务逻辑异常
          uni.$u.toast(data.message);
        }
        //只有冻结是2，4 用户不存在，其它正式错误码全是1，业务逻辑错误是9
        if (data.code == '4' || data.code == '2') {
          if (!isShowModal && useGlobalConfigStore().sessionId != '') {
            isShowModal = true;
            let modal = await uni.showModal({
              title: '温馨提示',
              content: data.code == '2' ? '用户已冻结' : '登录已过期，请重新登录',
            });
            if (modal.confirm) {
              globalConfig.sessionId.value = '';
              // #ifdef H5
              window.location.reload();
              // #endif
              // #ifndef H5
              uni.reLaunch({
                url: '/pages/subLogin/common/preLogin',
              });
              // #endif
            } else {
              isShowModal = false;
              // #ifdef H5
              closeWindow();
              // #endif
              // #ifdef MP-WEIXIN
              uni.exitMiniProgram();
              // #endif
            }
          } else {
            return Promise.reject(data);
          }
        } else {
          return data;
        }
        // // 如果需要catch返回，则进行reject
        // if (custom?.catch) {
        // 	return Promise.reject(data);
        // } else {
        // 	// 否则返回一个pending中的promise
        // 	return new Promise(() => {});
        // }
      } else {
        return data;
      }
    },
    (response) => {
      /*  对响应错误做点什么 （statusCode !== 200）*/
      uni.$u.toast('网络异常');
      return Promise.reject(response);
    },
  );
};

export { requestInterceptors, responseInterceptors };
